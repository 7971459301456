import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { ThemeProvider } from "@material-ui/core/styles";
import { CssBaseline, StyledEngineProvider } from "@material-ui/core";
import theme from "./config/themes/theme";
import NavigationScroll from "./layout/NavigationScroll";
import { SET_USER_PRIVILEGE, fetchAutoCompleteData } from "./store/actions";
import { store } from "./store";
import apiService from "./api/service";
import AppRoute from "./routes";

const App = () => {
  const dispatch = useDispatch();
  const customization = useSelector((state) => state.customization);
  const user = store.getState().account;

  useEffect(() => {
    if (!user.access_token) return;
    apiService.searchList.getSearchList().then((response) => {
      const autoCompleteData = response.data;
      const dataWithKeys = autoCompleteData.map((el, index) => {
        return { ...el, id: index };
      });
      fetchAutoCompleteData(dataWithKeys, dispatch);
    });

    if (user.email) {
      apiService.users.getUsersCheck(user.email).then((response) => {
        dispatch({
          type: SET_USER_PRIVILEGE,
          payload: {
            is_staff: response.data[0].is_superuser,
            is_superuser: response.data[0].is_staff
          }
        });
      });
    }
  }, [dispatch, user]);

  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={theme(customization)}>
        <CssBaseline />
        <NavigationScroll>
          <AppRoute />
        </NavigationScroll>
      </ThemeProvider>
    </StyledEngineProvider>
  );
};

export default App;
