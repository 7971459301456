import PropTypes from "prop-types";
import React from "react";
import { motion } from "framer-motion";
import { motionTransition, motionVariants } from "./MainLayout/MainLayout.styles";

const NavMotion = ({ children }) => {
  return (
    <motion.div initial="initial" animate="in" exit="out" variants={motionVariants} transition={motionTransition}>
      {children}
    </motion.div>
  );
};

NavMotion.propTypes = {
  children: PropTypes.node
};

export default NavMotion;
