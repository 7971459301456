import React, { useState } from "react";
import { VerificationForm } from "./components/VerificationForm/VerificationForm";
import apiService from "../../api/service";
import FormTopTitle from "../../components/FormTopTitle/FormTopTitle";
import FormWrapper from "../../components/FormWrapper";

const ForgotPasswordVerificationModule = ({ setVerificationField, setPasswordResetField, verificationNo, setVerificationNo }) => {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [validVerification, setValidVerification] = useState(true);

  const checkVerification = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);
    apiService.password
      .postPasswordResetValidateToken(verificationNo)
      .then(() => {
        setValidVerification(true);
        setVerificationField(false);
        setPasswordResetField(true);
      })
      .catch(() => {
        setValidVerification(false);
        setIsSubmitting(false);
      });
  };

  return (
    <FormWrapper topTileComponent={<FormTopTitle>Enter the verification code sent to your email</FormTopTitle>}>
      <VerificationForm
        verificationNo={verificationNo}
        setVerificationNo={setVerificationNo}
        validVerification={validVerification}
        isSubmitting={isSubmitting}
        checkVerification={checkVerification}
      />
    </FormWrapper>
  );
};

export default ForgotPasswordVerificationModule;
