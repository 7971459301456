import { apiDefault } from "../../axios/apiDefault.js";

export class PasswordService {
  async postPasswordReset(email) {
    const data = await apiDefault.post("password/reset/", {
      email: email
    });
    return data;
  }

  async postPasswordResetConfirm(body) {
    const data = await apiDefault.post("password/reset/confirm/", body);
    return data;
  }

  async postPasswordResetValidateToken(validateToken) {
    const data = await apiDefault.post("password/reset/validate_token/", {
      token: validateToken
    });
    return data;
  }
}
