import React from "react";
import { Link, Typography, Stack } from "@material-ui/core";

const AuthFooter = () => {
  return (
    <Stack direction="row" justifyContent="space-between">
      <Typography variant="subtitle2" component={Link} href={process.env.REACT_APP_LEMKEN_URL} target="_blank" underline="hover">
        lemken.com
      </Typography>
      <Typography variant="subtitle2" component={Link} href={process.env.REACT_APP_DOMAIN_URL} target="_blank" underline="hover">
        &copy; iQBlue.Digital
      </Typography>
    </Stack>
  );
};

export default AuthFooter;
