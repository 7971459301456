import React from "react";
import { NavGroup } from "../NavGroup/NavGroup";
import { useMenuItems } from "../../../../hooks/useMenuItems";

export const MenuList = () => {
  const menuItems = useMenuItems();

  const navItems = menuItems.items.map((item) => {
    return <NavGroup key={item.id} item={item} />;
  });
  return navItems;
};
