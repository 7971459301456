import { Typography } from "@material-ui/core";
import { makeStyles, styled } from "@material-ui/styles";
import { gridSpacing } from "../../store/constant";

export const Link = styled(Typography)(({ theme }) => ({
  display: "flex",
  color: theme.palette.grey[900],
  textDecoration: "none",
  alignContent: "center",
  alignItems: "center"
}));

export const ActiveLink = styled(Typography)(({ theme }) => ({
  display: "flex",
  textDecoration: "none",
  alignContent: "center",
  alignItems: "center",
  color: theme.palette.grey[500]
}));

export const useStyles = makeStyles((theme) => ({
  icon: {
    marginRight: theme.spacing(0.75),
    marginTop: "-" + theme.spacing(0.25),
    width: "16px",
    height: "16px",
    color: theme.palette.secondary.main
  },
  content: {
    padding: "16px !important"
  },
  card: {
    marginBottom: theme.spacing(gridSpacing),
    border: "1px solid",
    borderColor: theme.palette.primary[200] + 75
  }
}));
