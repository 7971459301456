import { makeStyles, styled } from "@material-ui/styles";
import { drawerWidth } from "../../store/constant";

export const Nav = styled("nav")(({ theme }) => ({
  [theme.breakpoints.up("md")]: {
    width: drawerWidth,
    flexShrink: 0
  }
}));

export const Box = styled("div")(({ theme }) => ({
  [theme.breakpoints.up("md")]: {
    display: "none"
  },
  [theme.breakpoints.down("md")]: {
    display: "block"
  }
}));

export const BoxContainer = styled("div")(() => ({
  display: "flex",
  padding: "16px",
  marginLeft: "auto",
  marginRight: "auto"
}));

export const useStyles = makeStyles((theme) => ({
  drawerPaper: {
    width: drawerWidth,
    background: theme.palette.background.default,
    color: theme.palette.text.primary,
    borderRight: "none",
    [theme.breakpoints.up("md")]: {
      top: "88px"
    }
  },
  ScrollHeight: {
    height: "calc(100vh - 88px)",
    paddingLeft: "16px",
    paddingRight: "16px",
    [theme.breakpoints.down("sm")]: {
      height: "calc(100vh - 56px)"
    }
  }
}));
