import React from "react";
import { Route, Switch, useLocation } from "react-router-dom";
import NavMotion from "../layout/NavMotion";
import LoginPage from "../pages/LoginPage";
import ForgotPasswordEmailPage from "../pages/ForgotPasswordEmailPage";
import GuestGuard from "./routeGuard/GuestGuard";

const LoginRoutes = () => {
  const location = useLocation();

  return (
    <Route path={["/login/", "/login/forgot-password/"]}>
      <Switch location={location} key={location.pathname}>
        <NavMotion>
          <GuestGuard>
            <Route exact path="/login/" component={LoginPage} />
            <Route exact path="/login/forgot-password/" component={ForgotPasswordEmailPage} />
          </GuestGuard>
        </NavMotion>
      </Switch>
    </Route>
  );
};

export default LoginRoutes;
