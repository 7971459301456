import PropTypes from "prop-types";
import React from "react";
import { IconMenu2 } from "@tabler/icons";
import * as Styled from "./Header.styles";
import { AppBar, Toolbar } from "@material-ui/core";
import { SearchSection } from "./components/SearchSection/SearchSection";
import { ProfileSection } from "./components/ProfileSection/ProfileSection";
import LogoSection from "../../components/LogoSection/LogoSection";

const HeaderModule = ({ handleLeftDrawerToggle, leftDrawerOpened }) => {
  const classes = Styled.useStyles();
  return (
    <AppBar
      enableColorOnDark
      position="fixed"
      color="inherit"
      elevation={0}
      className={leftDrawerOpened ? classes.appBarWidth : classes.appBar}
    >
      <Toolbar>
        <Styled.BoxContainer>
          <Styled.LogoWrap>
            <LogoSection />
          </Styled.LogoWrap>
          <Styled.ButtonBase>
            <Styled.Avatar variant="rounded" onClick={handleLeftDrawerToggle} color="inherit">
              <IconMenu2 stroke={1.5} size="20px" />
            </Styled.Avatar>
          </Styled.ButtonBase>
        </Styled.BoxContainer>

        <SearchSection theme="light" />
        <Styled.Grow />
        <Styled.Grow />
        <ProfileSection />
      </Toolbar>
    </AppBar>
  );
};

HeaderModule.propTypes = {
  handleLeftDrawerToggle: PropTypes.func,
  leftDrawerOpened: PropTypes.bool
};

export default HeaderModule;
