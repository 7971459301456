import React, { useState } from "react";
import { Box, FormHelperText, InputLabel, OutlinedInput, Typography } from "@material-ui/core";
import { Form, Formik } from "formik";
import { validateSchema } from "./helpers/validateSchema";
import FormSubmitButton from "../../../../components/UI/FormSubmitButton";
import useScriptRef from "../../../../hooks/useScriptRef.js";
import apiService from "../../../../api/service/api.service.js";
import AnimateButton from "../../../../components/AnimatedButton/AnimatedButton.jsx";
import { StyledFormControl } from "../../../../components/StyledComponents/StyledFormControl.js";

export const RestLoginForm = ({ setEmailField, setVerificationField, ...others }) => {
  const scriptedRef = useScriptRef();
  const [validEmail, setValidEmail] = useState(true);

  const handleSubmit = async (values, valuesHelper) => {
    const { setErrors, setStatus, setSubmitting } = valuesHelper;
    try {
      apiService.password
        .postPasswordReset(values.email)
        .then(() => {
          setEmailField(false);
          setVerificationField(true);
        })
        .catch(() => {
          setValidEmail(false);
          setStatus({ success: false });
          setSubmitting(false);
        });
    } catch (err) {
      if (scriptedRef.current) {
        setStatus({ success: false });
        setErrors({ submit: err.message });
        setSubmitting(false);
      }
    }
  };

  return (
    <React.Fragment>
      <Formik
        initialValues={{
          email: "",
          submit: null
        }}
        validationSchema={validateSchema}
        onSubmit={handleSubmit}
      >
        {({ errors, handleBlur, handleChange, handleSubmit, isSubmitting, touched, values }) => (
          <Form noValidate onSubmit={handleSubmit} {...others}>
            <StyledFormControl fullWidth error={Boolean(touched.email && errors.email)}>
              <InputLabel htmlFor="outlined-adornment-email-login">Email</InputLabel>
              <OutlinedInput
                id="outlined-adornment-email-login"
                type="email"
                value={values.email}
                name="email"
                onBlur={handleBlur}
                onChange={handleChange}
                label="Email"
              />
              {!validEmail && <Typography color="error">Invalid email</Typography>}
              {touched.email && errors.email && (
                <FormHelperText error id="standard-weight-helper-text-email-login">
                  {errors.email}
                </FormHelperText>
              )}
            </StyledFormControl>

            {errors.submit && (
              <Box sx={{ mt: 3 }}>
                <FormHelperText error>{errors.submit}</FormHelperText>
              </Box>
            )}

            <Box sx={{ mt: 2 }}>
              <AnimateButton>
                <FormSubmitButton disabled={isSubmitting}>Send Verfication Code</FormSubmitButton>
              </AnimateButton>
            </Box>
          </Form>
        )}
      </Formik>
    </React.Fragment>
  );
};
