import { LOAD_AUTOCOMPLETE_DATA } from "./actions";

const autoCompleteReducer = (state = [], action) => {
  switch (action.type) {
    case LOAD_AUTOCOMPLETE_DATA: {
      return action.payload;
    }
    default: {
      return state;
    }
  }
};
export default autoCompleteReducer;
