import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { Card, CardContent, Grid, Typography } from "@material-ui/core";
import MuiBreadcrumbs from "@material-ui/core/Breadcrumbs";
import HomeIcon from "@material-ui/icons/Home";
import config from "../../config/config";
import * as Styled from "./BreadcrumbsModule.styles";
import { useMenuItems } from "../../hooks/useMenuItems";

const BreadcrumbsModule = ({ icon, rightAlign, separator, title, ...others }) => {
  const classes = Styled.useStyles();
  const [main, setMain] = useState([]);
  const [item, setItem] = useState([]);
  const location = useLocation();
  const menuItems = useMenuItems();

  useEffect(() => {
    const getCollapse = (item) => {
      if (item.children) {
        item.children.filter((collapse) => {
          if (collapse.type && collapse.type === "collapse") {
            getCollapse(collapse);
          } else if (collapse.type && collapse.type === "item") {
            if (document.location.pathname === config.basename + collapse.url) {
              setMain(item);
              setItem(collapse);
            }
          }
          return false;
        });
      }
    };

    menuItems.items.map((item) => {
      if (item.type && item.type === "group") {
        getCollapse(item);
      }
      return false;
    });
  }, [menuItems, location]);

  const SeparatorIcon = separator;
  const separatorIcon = separator ? <SeparatorIcon stroke={1.5} size="16px" /> : "/";

  let mainContent, itemContent;
  let breadcrumbContent = "";
  let itemTitle = "";

  if (main && main.type === "collapse") {
    mainContent = (
      <Styled.Link component={Link} to="#" variant="subtitle1">
        {main.title}
      </Styled.Link>
    );
  }

  if (item && item.type === "item") {
    itemTitle = item.title;

    itemContent = <Styled.ActiveLink variant="subtitle1">{itemTitle}</Styled.ActiveLink>;

    if (item.breadcrumbs !== false) {
      breadcrumbContent = (
        <Card className={classes.card} {...others}>
          <CardContent className={classes.content}>
            <Grid
              container
              direction={rightAlign ? "row" : "column"}
              justifyContent={rightAlign ? "space-between" : "flex-start"}
              alignItems={rightAlign ? "center" : "flex-start"}
              spacing={1}
            >
              {title && (
                <Grid item>
                  <Typography variant="h3" sx={{ fontWeight: 500 }}>
                    {item.title}
                  </Typography>
                </Grid>
              )}
              <Grid item>
                <MuiBreadcrumbs aria-label="breadcrumb" maxItems={8} separator={separatorIcon}>
                  <Styled.Link component={Link} to="/" color="inherit" variant="subtitle1">
                    {icon && <HomeIcon className={classes.icon} sx={{ marginRight: 0 }} />}
                    {!icon && "Dashboard"}
                  </Styled.Link>
                  {mainContent}
                  {itemContent}
                </MuiBreadcrumbs>
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      );
    }
  }

  return breadcrumbContent;
};

BreadcrumbsModule.propTypes = {
  icon: PropTypes.bool,
  rightAlign: PropTypes.bool,
  separator: PropTypes.oneOfType([PropTypes.func, PropTypes.object]),
  title: PropTypes.bool
};

export default BreadcrumbsModule;
