import React, { useState } from "react";
import EmailModule from "../../modules/EmailModule/EmailModule";
import ForgotPasswordVerificationModule from "../../modules/ForgotPasswordVerificationModule/ForgotPasswordVerficationModule";
import PasswordResetModule from "../../modules/PasswordResetModule/PasswordResetModule";

const ForgotPasswordEmailPage = () => {
  const [emailField, setEmailField] = useState(true);
  const [verificationField, setVerificationField] = useState(false);
  const [passwordResetField, setPasswordResetField] = useState(false);
  const [verificationNo, setVerificationNo] = useState("");

  return (
    <>
      {emailField && <EmailModule setEmailField={setEmailField} setVerificationField={setVerificationField} />}
      {verificationField && (
        <ForgotPasswordVerificationModule
          setVerificationField={setVerificationField}
          setPasswordResetField={setPasswordResetField}
          verificationNo={verificationNo}
          setVerificationNo={setVerificationNo}
        />
      )}
      {passwordResetField && <PasswordResetModule verificationNo={verificationNo} />}
    </>
  );
};

export default ForgotPasswordEmailPage;
