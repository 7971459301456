import { styled } from "@material-ui/styles";
import { Card as MuiCard } from "@material-ui/core";

export const Card = styled(MuiCard)(({ theme }) => ({
  border: "1px solid",
  borderColor: theme.palette.primary[200] + 75,
  ":hover": {
    boxShadow: "inherit"
  }
}));
