export const LOGOUT = "LOGOUT";
export const ACCOUNT_INITIALIZE = "ACCOUNT_INITIALIZE";
export const SET_USER_PRIVILEGE = "SET_USER_PRIVILEGE";

export const SET_MENU = "@customization/SET_MENU";
export const MENU_OPEN = "@customization/MENU_OPEN";

export const LOAD_AUTOCOMPLETE_DATA = "LOAD_AUTOCOMPLETE_DATA";

export const fetchAutoCompleteData = (autoCompleteData, dispatch) => {
  dispatch({
    type: LOAD_AUTOCOMPLETE_DATA,
    payload: autoCompleteData
  });
};
