import React from "react";
import FormTopTitle from "../../components/FormTopTitle/FormTopTitle";
import { PasswordResetForm } from "./components/PasswordResetForm/PasswordResetForm";
import FormWrapper from "../../components/FormWrapper";

const PasswordResetModule = ({ verificationNo }) => {
  return (
    <FormWrapper topTileComponent={<FormTopTitle>Enter New Password</FormTopTitle>}>
      <PasswordResetForm verificationNo={verificationNo} />
    </FormWrapper>
  );
};

export default PasswordResetModule;
