import React from "react";
import { useTheme } from "@material-ui/core";
import { Typography, useMediaQuery } from "@material-ui/core";
import PropTypes from "prop-types";

const FormTopTitle = ({ children }) => {
  const theme = useTheme();
  const matchDownSM = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <Typography variant="caption" fontSize="16px" textAlign={matchDownSM ? "center" : ""}>
      {children}
    </Typography>
  );
};

FormTopTitle.propTypes = {
  children: PropTypes.node
};

export default FormTopTitle;
