import { createTheme } from "@material-ui/core/styles";
import colors from "./colors.js";
import { componentStyleOverrides } from "./compStyleOverride.js";
import { themePalette } from "./palette.js";
import { themeTypography } from "./typography.js";

export function theme(customization) {
  const color = colors;

  let themeOption = {
    colors: color,
    heading: color.grey900,
    paper: color.paper,
    backgroundDefault: color.paper,
    background: color.primaryLight,
    darkTextPrimary: color.grey700,
    darkTextSecondary: color.grey500,
    textDark: color.grey900,
    menuSelected: color.secondaryDark,
    menuSelectedBack: color.secondaryLight,
    divider: color.grey200,
    customization: customization
  };

  return createTheme({
    direction: "ltr",
    palette: themePalette(themeOption),
    mixins: {
      toolbar: {
        minHeight: "48px",
        padding: "16px",
        "@media (min-width: 600px)": {
          minHeight: "48px"
        }
      }
    },
    breakpoints: {
      values: {
        xs: 0,
        sm: 600,
        md: 960,
        lg: 1280,
        xl: 1920
      }
    },
    typography: themeTypography(themeOption),
    components: componentStyleOverrides(themeOption)
  });
}

export default theme;
