import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { Box, FormHelperText, IconButton, InputAdornment, InputLabel, OutlinedInput, Typography } from "@material-ui/core";
import { Form, Formik } from "formik";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import { StyledFormControl } from "../../../../components/StyledComponents/StyledFormControl";
import FormSubmitButton from "../../../../components/UI/FormSubmitButton/FormSubmitButton";
import { passwordResetValidateSchema } from "./helpers/validateSchema";
import AnimateButton from "../../../../components/AnimatedButton/AnimatedButton";
import apiService from "../../../../api/service/api.service";

export const PasswordResetForm = ({ verificationNo, ...others }) => {
  const history = useHistory();
  const [showPasswordConfirm, setShowPasswordConfirm] = useState(false);
  const [showPasswordError, setShowPasswordError] = useState(false);
  const [showPasswordSubmitError, setShowPasswordSubmitError] = useState(false);
  const [errorData, setErrorData] = useState("");
  const [showPassword, setShowPassword] = useState(false);

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleClickShowPasswordConfirm = () => {
    setShowPasswordConfirm(!showPasswordConfirm);
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const handleSubmit = (values) => {
    if (values.password !== values.passwordConfirm) {
      setShowPasswordError(true);
      setShowPasswordSubmitError(false);
    }
    if (values.password === values.passwordConfirm) {
      apiService.password
        .postPasswordResetConfirm({
          password: values.password,
          token: verificationNo
        })
        .then(() => {
          history.push("/login");
        })
        .catch((err) => {
          setShowPasswordSubmitError(true);
          setShowPasswordError(false);
          if (err.response.data.password) {
            setErrorData(err.response.data.password[0]);
          } else {
            setErrorData(err.response.data);
          }
        });
    }
  };

  return (
    <>
      {showPasswordError && <Typography color="error">{"Passwords didn't match"}</Typography>}
      {showPasswordSubmitError && <Typography color="error">{errorData}</Typography>}
      <Formik
        initialValues={{
          passwordConfirm: "",
          password: "",
          submit: null
        }}
        validationSchema={passwordResetValidateSchema}
        onSubmit={handleSubmit}
      >
        {({ errors, handleBlur, isSubmitting, touched, values, handleChange, handleSubmit }) => (
          <Form noValidate {...others} onSubmit={handleSubmit}>
            <StyledFormControl fullWidth>
              <InputLabel htmlFor="outlined-adornment-password-login">New Password</InputLabel>
              <OutlinedInput
                id="outlined-adornment-password-login"
                type={showPassword ? "text" : "password"}
                value={values.password}
                name="password"
                onBlur={handleBlur}
                onChange={handleChange}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPassword}
                      onMouseDown={handleMouseDownPassword}
                      edge="end"
                    >
                      {showPassword ? <Visibility /> : <VisibilityOff />}
                    </IconButton>
                  </InputAdornment>
                }
                label="Password"
              />
              {touched.password && errors.password && (
                <FormHelperText error id="standard-weight-helper-text-password-login">
                  {errors.password}
                </FormHelperText>
              )}
            </StyledFormControl>

            <StyledFormControl fullWidth>
              <InputLabel htmlFor="outlined-adornment-password-login2">Confirm Password</InputLabel>
              <OutlinedInput
                id="outlined-adornment-password-login2"
                type={showPasswordConfirm ? "text" : "password"}
                value={values.passwordConfirm}
                name="passwordConfirm"
                onBlur={handleBlur}
                onChange={handleChange}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPasswordConfirm}
                      onMouseDown={handleMouseDownPassword}
                      edge="end"
                    >
                      {showPasswordConfirm ? <Visibility /> : <VisibilityOff />}
                    </IconButton>
                  </InputAdornment>
                }
                label="Password2"
              />
              {touched.passwordConfirm && errors.passwordConfirm && (
                <FormHelperText error id="standard-weight-helper-text-password-login2">
                  {errors.password}
                </FormHelperText>
              )}
            </StyledFormControl>

            {errors.submit && (
              <Box sx={{ mt: 3 }}>
                <FormHelperText error>{errors.submit}</FormHelperText>
              </Box>
            )}

            <Box sx={{ mt: 2 }}>
              <AnimateButton>
                <FormSubmitButton disabled={isSubmitting}>Save</FormSubmitButton>
              </AnimateButton>
            </Box>
          </Form>
        )}
      </Formik>
    </>
  );
};
