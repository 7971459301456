import { LOGOUT } from "../../store/actions";
import { store } from "../../store";

const { dispatch } = store;

const onRequest = (config) => {
  const state = store.getState();
  const access_token = state.account.access_token;
  config.headers["Authorization"] = `Bearer ${access_token}`;

  return config;
};

const onRequestError = (error) => {
  return Promise.reject(error);
};

const onResponse = (response) => {
  return response;
};

const onResponseError = async (error) => {
  // Handle async response errors
  console.log("Response error", error.response);

  if (error.response) {
    // Access Token was expired
    if (error.response.status === 500) {
      console.log("Status 500 error");
    }

    if (error.response.data[1].detail === "bad_authorization_header" || error.response.data[1].code === "token_not_valid") {
      dispatch({
        type: LOGOUT
      });
    }
  }
  return Promise.reject(error);
};

export const setupInterceptorsTo = (axiosInstance) => {
  axiosInstance.interceptors.request.use(onRequest, onRequestError);
  axiosInstance.interceptors.response.use(onResponse, onResponseError);
  return axiosInstance;
};
