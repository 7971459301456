import React from "react";
import { Redirect, Switch } from "react-router-dom";
import MainRoutes from "./MainRoutes";
import LoginRoutes from "./LoginRoutes";
import config from "../config/config";

const AppRoute = () => {
  return (
    <Switch>
      <Redirect exact from="/" to={config.defaultPath} />
      <>
        <LoginRoutes />
        <MainRoutes />
      </>
    </Switch>
  );
};

export default AppRoute;
