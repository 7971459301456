import React from "react";
import useCheckUserRole from "../../hooks/useCheckUserRole";
import { Redirect } from "react-router-dom";

const AdminGuard = ({ children }) => {
  const isAdmin = useCheckUserRole();

  if (!isAdmin) {
    return <Redirect to="/licenses" />;
  }

  return children;
};

export default AdminGuard;
