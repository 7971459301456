import PropTypes from "prop-types";
import React, { Suspense, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTheme } from "@material-ui/styles";
import { useMediaQuery } from "@material-ui/core";
import clsx from "clsx";
import { SET_MENU } from "../../store/actions";
import { IconChevronRight } from "@tabler/icons";
import SideBarModule from "../../modules/SideBarModule";
import * as Styled from "./MainLayout.styles";
import Loader from "../../components/UI/Loader/Loader";
import HeaderModule from "../../modules/HeaderModule/HeaderModule";
import BreadcrumbsModule from "../../modules/BreadcrumbsModule/BreadcrumbsModule";

const MainLayout = ({ children }) => {
  const classes = Styled.useStyles();
  const theme = useTheme();
  const matchDownMd = useMediaQuery(theme.breakpoints.down("md"));
  const leftDrawerOpened = useSelector((state) => state.customization.opened);
  const dispatch = useDispatch();

  const handleLeftDrawerToggle = () => {
    dispatch({ type: SET_MENU, opened: !leftDrawerOpened });
  };

  useEffect(() => {
    dispatch({ type: SET_MENU, opened: !matchDownMd });
  }, [dispatch, matchDownMd]);

  return (
    <Styled.Root>
      <HeaderModule handleLeftDrawerToggle={handleLeftDrawerToggle} leftDrawerOpened={leftDrawerOpened} />
      <SideBarModule drawerOpen={leftDrawerOpened} drawerToggle={handleLeftDrawerToggle} />

      <main
        className={clsx([
          classes.content,
          {
            [classes.contentShift]: leftDrawerOpened
          }
        ])}
      >
        <BreadcrumbsModule separator={IconChevronRight} icon title rightAlign />
        <div>
          <Suspense fallback={<Loader />}>{children}</Suspense>
        </div>
      </main>
    </Styled.Root>
  );
};

MainLayout.propTypes = {
  children: PropTypes.node
};

export default MainLayout;
