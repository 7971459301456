import { makeStyles } from "@material-ui/styles";

export const useStyles = makeStyles((theme) => ({
  listIcon: {
    minWidth: "18px",
    marginTop: "auto",
    marginBottom: "auto"
  },
  listCustomIconSub: {
    width: "6px",
    height: "6px"
  },
  listCustomIconSubActive: {
    width: "8px",
    height: "8px"
  },
  listItem: {
    marginBottom: "5px",
    alignItems: "center"
  },
  listItemNoBack: {
    marginBottom: "5px",
    backgroundColor: "transparent !important",
    paddingTop: "8px",
    paddingBottom: "8px",
    alignItems: "flex-start"
  },
  subMenuCaption: {
    ...theme.typography.subMenuCaption
  }
}));
