import React from "react";
import RestLoginFrom from "./components/RestLoginForm/RestLoginForm";
import { TopTitle } from "./components/TopTitle/TopTitle";
import FormWrapper from "../../components/FormWrapper";

const LoginModule = () => {
  return (
    <FormWrapper topTileComponent={<TopTitle />}>
      <RestLoginFrom />
    </FormWrapper>
  );
};

export default LoginModule;
