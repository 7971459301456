import { AccountsService } from "./helpers/accounts.js";
import { CommunicationsUnitsService } from "./helpers/communicationsUnits.js";
import { DetailsService } from "./helpers/details.js";
import { LicenceTypesService } from "./helpers/licenceTypes.js";
import { LicencesService } from "./helpers/licences.js";
import { MyCCIsService } from "./helpers/myccis.js";
import { PasswordService } from "./helpers/password.js";
import { SearchListService } from "./helpers/searchList.js";
import { TokenService } from "./helpers/token.js";
import { UsersService } from "./helpers/users.js";

class ApiService {
  accounts = new AccountsService();
  communicationsUnits = new CommunicationsUnitsService();
  details = new DetailsService();
  licences = new LicencesService();
  licenceTypes = new LicenceTypesService();
  myCCIs = new MyCCIsService();
  password = new PasswordService();
  searchList = new SearchListService();
  token = new TokenService();
  users = new UsersService();
}

const apiService = new ApiService();
export default apiService;
