import { Avatar as MuiAvatar, ButtonBase as MuiButtonBase } from "@material-ui/core";
import { styled } from "@material-ui/styles";
import { makeStyles } from "@material-ui/styles";

export const useStyles = makeStyles((theme) => ({
  appBar: {
    backgroundColor: theme.palette.background.default
  },
  appBarWidth: {
    transition: theme.transitions.create("width"),
    backgroundColor: theme.palette.background.default
  }
}));

export const BoxContainer = styled("div")(({ theme }) => ({
  width: "228px",
  display: "flex",
  [theme.breakpoints.down("md")]: {
    width: "auto"
  }
}));

export const LogoWrap = styled("span")(({ theme }) => ({
  flexGrow: 1,
  [theme.breakpoints.up("md")]: {
    display: "block"
  },
  [theme.breakpoints.down("md")]: {
    display: "none"
  }
}));

export const ButtonBase = styled(MuiButtonBase)(() => ({
  borderRadius: "12px",
  overflow: "hidden"
}));

export const Grow = styled("div")(() => ({
  flexGrow: 1
}));

export const Avatar = styled(MuiAvatar)(({ theme }) => ({
  ...theme.typography.commonAvatar,
  ...theme.typography.mediumAvatar,
  transition: "all .2s ease-in-out",
  background: theme.palette.secondary.light,
  color: theme.palette.secondary.dark,
  "&:hover": {
    background: theme.palette.secondary.dark,
    color: theme.palette.secondary.light
  }
}));
