import axios from "axios";
import { setupInterceptorsTo } from "./interceptors";

const api = setupInterceptorsTo(
  axios.create({
    baseURL: "/api",
    headers: {
      "Content-Type": "application/json"
    }
  })
);

export default api;
