import { Autocomplete as MuiAutocomplete } from "@material-ui/core";
import { styled } from "@material-ui/styles";

export const Autocomplete = styled(MuiAutocomplete)(({ theme }) => ({
  width: "434px",
  marginLeft: "16px",
  paddingRight: "16px",
  paddingLeft: "16px",
  "& input": {
    background: "transparent !important",
    paddingLeft: "5px !important"
  },
  [theme.breakpoints.down("lg")]: {
    width: "250px"
  },
  [theme.breakpoints.down("md")]: {
    width: "100%",
    marginLeft: "4px",
    background: "#fff"
  }
}));
