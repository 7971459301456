import React, { lazy } from "react";
import { Route, Switch, useLocation } from "react-router-dom";
import MainLayout from "../layout/MainLayout";
import AuthGuard from "./routeGuard/AuthGuard";
import AdminGuard from "./routeGuard/AdminGuard";

const ComUnits = lazy(() => import("../pages/CommunicationPage"));
const MyCCIs = lazy(() => import("../pages/MyCCIsInfoPage"));
const Licences = lazy(() => import("../pages/LicencesPage"));
const Details = lazy(() => import("../pages/DetailsPage"));
const Users = lazy(() => import("../pages/UsersPage"));

const MainRoutes = () => {
  const location = useLocation();

  return (
    <Route path={["/communicationUnits", "/myCCIs", "/licenses", "/details/:SerialNumber", "/users"]}>
      <MainLayout>
        <Switch location={location} key={location.pathname}>
          <AuthGuard>
            <Route path="/licenses" component={Licences} />
            <AdminGuard>
              <Route path="/communicationUnits" component={ComUnits} />
              <Route path="/myCCIs" component={MyCCIs} />
              <Route path="/details/:SerialNumber" component={Details} />
              <Route path="/users" component={Users} />
            </AdminGuard>
          </AuthGuard>
        </Switch>
      </MainLayout>
    </Route>
  );
};

export default MainRoutes;
