const colors = {
  paper: "#ffffff",

  primaryLight: "#e3f2fd",
  primaryMain: "#2196f3",
  primaryDark: "#1e88e5",
  primary200: "#90caf9",
  primary800: "#1565c0",

  secondaryLight: "#ede7f6",
  secondaryMain: "#673ab7",
  secondaryDark: "#5e35b1",
  secondary200: "#b39ddb",
  secondary800: "#4527a0",
  secondaryContrastText: "#ffffff",

  additionalMain: "#212b36",
  additionalLight: "#637381",

  successLight: "#b9f6ca",
  success200: "#69f0ae",
  successMain: "#00e676",
  successDark: "#00c853",

  errorLight: "#ef9a9a",
  errorMain: "#f44336",
  errorDark: "#c62828",

  orangeLight: "#fbe9e7",
  orangeMain: "#ffab91",
  orangeDark: "#d84315",

  warningLight: "#fff8e1",
  warningMain: "#ffe57f",
  warningDark: "#ffc107",

  grey50: "#fafafa",
  grey100: "#f5f5f5",
  grey200: "#eeeeee",
  grey300: "#e0e0e0",
  grey500: "#9e9e9e",
  grey600: "#757575",
  grey700: "#616161",
  grey900: "#212121"
};

export default colors;
