import { ACCOUNT_INITIALIZE, LOGOUT, SET_USER_PRIVILEGE } from "./actions";

export const initialState = {
  access_token: "",
  refresh_token: "",
  isLoggedIn: false,
  isInitialized: false,
  email: "",
  is_superuser: false,
  is_staff: false
};

const accountReducer = (state = initialState, action) => {
  switch (action.type) {
    case ACCOUNT_INITIALIZE: {
      const { isLoggedIn, access_token, refresh_token, email } = action.payload;
      return {
        ...state,
        isLoggedIn,
        isInitialized: true,
        access_token,
        refresh_token,
        email
      };
    }
    case LOGOUT: {
      return {
        ...state,
        isLoggedIn: false,
        access_token: "",
        refresh_token: ""
      };
    }
    case SET_USER_PRIVILEGE: {
      return {
        ...state,
        is_staff: action.payload.is_staff,
        is_superuser: action.payload.is_superuser
      };
    }
    default: {
      return { ...state };
    }
  }
};

export default accountReducer;
