import PropTypes from "prop-types";
import React from "react";
import MainCard from "../MainCard";
import { useStyles } from "./AuthCardWrapper.styles";

const AuthCardWrapper = ({ children, ...other }) => {
  const classes = useStyles();

  return (
    <MainCard className={classes.card} contentClass={classes.content} {...other}>
      {children}
    </MainCard>
  );
};

AuthCardWrapper.propTypes = {
  children: PropTypes.node
};

export default AuthCardWrapper;
