import React from "react";
import LinearProgress from "@material-ui/core/LinearProgress";
import * as Styled from "./Loader.styles";

const Loader = () => {
  return (
    <Styled.LoaderWrapper>
      <LinearProgress data-testid="loader" color="primary" />
    </Styled.LoaderWrapper>
  );
};

export default Loader;
