import React from "react";
import AuthCardWrapper from "../AuthCardWrapper";
import { Link } from "react-router-dom";
import Logo from "../UI/Logo";
import { useTheme } from "@material-ui/core";
import { Divider, Grid, Stack, Typography, useMediaQuery } from "@material-ui/core";
import AuthFooter from "../AuthFooter/AuthFooter";
import PropTypes from "prop-types";
import * as Styled from "./FormWrapper.styles";

const FormWrapper = ({ children, topTileComponent }) => {
  const theme = useTheme();
  const matchDownSM = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <Styled.AuthWrapper>
      <Grid container direction="column" justifyContent="flex-end" sx={{ minHeight: "100vh" }}>
        <Grid item xs={12}>
          <Grid container justifyContent="center" alignItems="center" sx={{ minHeight: "calc(100vh - 68px)" }}>
            <Grid item sx={{ m: { xs: 1, sm: 3 }, marginBottom: 0 }}>
              <AuthCardWrapper>
                <Grid container spacing={2} alignItems="center" justifyContent="center">
                  <Grid item sx={{ marginBottom: 1 }}>
                    <Link to="/">
                      <Logo />
                    </Link>
                  </Grid>
                  <Grid item xs={12}>
                    <Grid container direction={matchDownSM ? "column-reverse" : "row"} alignItems="center" justifyContent="center">
                      <Grid item>
                        <Stack alignItems="center" justifyContent="center" spacing={1}>
                          {topTileComponent}
                        </Stack>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={12}>
                    {children}
                  </Grid>
                  <Grid item xs={12}>
                    <Divider />
                  </Grid>
                  <Grid item xs={12}>
                    <Grid item container direction="column" alignItems="center" xs={12} textAlign={matchDownSM ? "center" : ""}>
                      <Typography component={"div"} variant="subtitle1" sx={{ textDecoration: "none" }}>
                        <Typography variant="subtitle1" sx={{ textDecoration: "none" }}>
                          {"Don't have an account? Please contact Lemken!"}
                        </Typography>
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
              </AuthCardWrapper>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} sx={{ margin: 3, marginTop: 1 }}>
          <AuthFooter />
        </Grid>
      </Grid>
    </Styled.AuthWrapper>
  );
};

FormWrapper.propTypes = {
  children: PropTypes.node,
  topTileComponent: PropTypes.element
};

export default FormWrapper;
