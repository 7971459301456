import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ListItemIcon, ListItemText, Typography } from "@material-ui/core";
import ListItemButton from "@material-ui/core/ListItemButton";
import { IconLogout } from "@tabler/icons";
import apiService from "../../../../api/service/api.service";
import { LOGOUT } from "../../../../store/actions";

export const ProfileSection = () => {
  const customization = useSelector((state) => state.customization);
  const account = useSelector((state) => state.account);
  const dispatcher = useDispatch();
  const [selectedIndex] = useState(1);
  const [open] = useState(false);
  const anchorRef = useRef(null);
  const prevOpen = useRef(open);

  const handleLogout = () => {
    apiService.accounts.postAccountsLogout(account.access_token).then(function () {
      dispatcher({ type: LOGOUT });
    });
  };

  useEffect(() => {
    if (prevOpen.current === true && open === false) {
      anchorRef.current.focus();
    }
    prevOpen.current = open;
  }, [open]);

  return (
    <Typography component={"div"}>
      <ListItemButton
        sx={{ marginTop: "5px", borderRadius: customization.borderRadius + "px" }}
        selected={selectedIndex === 4}
        onClick={handleLogout}
      >
        <ListItemIcon>
          <IconLogout stroke={1.5} size="24px" />
        </ListItemIcon>
        <ListItemText primary={<Typography variant="body2">Logout</Typography>} />
      </ListItemButton>
    </Typography>
  );
};
