import { IconBrandChrome, IconDashboard, IconDeviceAnalytics, IconHelp, IconSitemap, IconUsers } from "@tabler/icons";

const icons = {
  IconDashboard: IconDashboard,
  IconDeviceAnalytics,
  IconBrandChrome: IconBrandChrome,
  IconHelp: IconHelp,
  IconSitemap: IconSitemap,
  IconUsers: IconUsers
};

export const simpleDashboard = {
  id: "dashboard",
  type: "group",
  children: [
    {
      id: "default",
      title: "License Details",
      type: "item",
      url: "/licenses",
      icon: icons["IconSitemap"],
      breadcrumbs: true
    }
  ]
};

export const adminDashboard = {
  id: "dashboard",
  type: "group",
  children: [
    {
      id: "default",
      title: "License Details",
      type: "item",
      url: "/licenses",
      icon: icons["IconSitemap"],
      breadcrumbs: true
    },
    {
      id: "sample-page",
      title: "Communication Units",
      type: "item",
      url: "/communicationUnits",
      icon: icons["IconBrandChrome"],
      breadcrumbs: true
    },
    {
      id: "MyCCIs",
      title: "MyCCIs Info",
      type: "item",
      url: "/myCCIs",
      icon: icons["IconDeviceAnalytics"],
      breadcrumbs: true
    },
    {
      id: "UserData",
      title: "Users",
      type: "item",
      url: "/users",
      icon: icons["IconUsers"],
      breadcrumbs: true
    }
  ]
};
