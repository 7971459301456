import { styled } from "@material-ui/styles";

export const LoaderWrapper = styled("div")(({ theme }) => ({
  position: "fixed",
  top: 0,
  left: 0,
  zIndex: 1301,
  width: "100%",
  "& > * + *": {
    marginTop: theme.spacing(2)
  }
}));
