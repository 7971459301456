import PropTypes from "prop-types";
import React from "react";
import { List } from "@material-ui/core";
import { NavItem } from "../NavItem/NavItem";
import * as Styled from "./NavGroup.styles";

export const NavGroup = ({ item }) => {
  const items = item.children.map((menu) => <NavItem key={menu.id} item={menu} level={1} />);

  return (
    <>
      <List
        subheader={
          item.title && (
            <Styled.Title variant="caption" gutterBottom>
              {item.title}
              {item.caption && (
                <Styled.Caption variant="caption" gutterBottom>
                  {item.caption}
                </Styled.Caption>
              )}
            </Styled.Title>
          )
        }
      >
        {items}
      </List>

      <Styled.Driver />
    </>
  );
};

NavGroup.propTypes = {
  item: PropTypes.object
};
