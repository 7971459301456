import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";
import {
  Box,
  Checkbox,
  FormControlLabel,
  FormHelperText,
  IconButton,
  InputAdornment,
  InputLabel,
  OutlinedInput,
  Stack,
  Typography
} from "@material-ui/core";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import { Form, Formik } from "formik";
import useScriptRef from "../../../../hooks/useScriptRef.js";
import apiService from "../../../../api/service/api.service.js";
import { ACCOUNT_INITIALIZE } from "../../../../store/actions.js";
import AnimateButton from "../../../../components/AnimatedButton";
import { restLoginFormValidateSchema } from "./helpers/validateSchema.js";
import * as Styled from "./RestLoginForm.styles.js";
import FormSubmitButton from "../../../../components/UI/FormSubmitButton";
import { StyledFormControl } from "../../../../components/StyledComponents/StyledFormControl.js";

const RestLoginFrom = () => {
  const dispatcher = useDispatch();
  const history = useHistory();

  const scriptedRef = useScriptRef();
  const [checked, setChecked] = useState(true);
  const [loginFailed, setLoginFailed] = useState(false);
  const [formSubmitting, setFormSubmitting] = useState(false);

  const [showPassword, setShowPassword] = useState(false);
  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleToggleCheckbox = (event) => setChecked(event.target.checked);

  const handleMouseDownPassword = (event) => event.preventDefault();

  const handleRouteTo = () => history.push("/login/forgot-password/");

  const handleSubmit = async (values, valuesHelper) => {
    const { setErrors, setStatus, setSubmitting } = valuesHelper;
    setFormSubmitting(true);
    try {
      apiService.token
        .postTokenGet({
          email: values.email,
          password: values.password
        })
        .then(function (response) {
          if (response.data.refresh) {
            setFormSubmitting(false);
            setLoginFailed(false);
            dispatcher({
              type: ACCOUNT_INITIALIZE,
              payload: {
                isLoggedIn: true,
                access_token: response.data.access,
                refresh_token: response.data.refresh,
                email: values.email
              }
            });
            if (scriptedRef.current) {
              setStatus({ success: true });
              setSubmitting(false);
            }
          } else {
            setStatus({ success: false });
            setErrors({ submit: response });
            setSubmitting(false);
          }
        })
        .catch(() => {
          setLoginFailed(true);
          setFormSubmitting(false);
          setStatus({ success: false });
          setSubmitting(false);
        });
    } catch (err) {
      if (scriptedRef.current) {
        setStatus({ success: false });
        setErrors({ submit: err.message });
        setSubmitting(false);
      }
    }
  };

  return (
    <Formik
      initialValues={{
        email: "",
        password: "",
        submit: null
      }}
      validationSchema={restLoginFormValidateSchema}
      onSubmit={handleSubmit}
    >
      {({ errors, handleBlur, handleChange, handleSubmit, touched, values }) => (
        <Form noValidate onSubmit={handleSubmit}>
          {loginFailed && <Typography color="red">Invalid email or password</Typography>}

          <StyledFormControl fullWidth error={Boolean(touched.email && errors.email)}>
            <InputLabel htmlFor="outlined-adornment-email-login">Email</InputLabel>
            <OutlinedInput
              id="outlined-adornment-email-login"
              type="email"
              value={values.email}
              name="email"
              onBlur={handleBlur}
              onChange={handleChange}
              label="Email"
            />
            {touched.email && errors.email && (
              <FormHelperText error id="standard-weight-helper-text-email-login">
                {errors.email}
              </FormHelperText>
            )}
          </StyledFormControl>

          <StyledFormControl fullWidth error={Boolean(touched.password && errors.password)}>
            <InputLabel htmlFor="outlined-adornment-password-login">Password</InputLabel>
            <OutlinedInput
              id="outlined-adornment-password-login"
              type={showPassword ? "text" : "password"}
              value={values.password}
              name="password"
              onBlur={handleBlur}
              onChange={handleChange}
              endAdornment={
                <InputAdornment position="end">
                  <IconButton
                    data-testid="show-password"
                    aria-label="toggle password visibility"
                    onClick={handleClickShowPassword}
                    onMouseDown={handleMouseDownPassword}
                    edge="end"
                  >
                    {showPassword ? <Visibility /> : <VisibilityOff />}
                  </IconButton>
                </InputAdornment>
              }
              label="Password"
            />

            {touched.password && errors.password && (
              <FormHelperText error id="standard-weight-helper-text-password-login">
                {errors.password}
              </FormHelperText>
            )}
          </StyledFormControl>

          <Stack direction="row" alignItems="center" justifyContent="space-between" spacing={1}>
            <FormControlLabel
              control={<Checkbox checked={checked} onChange={handleToggleCheckbox} name="checked" color="primary" />}
              label="Remember me"
            />

            <Styled.Typography variant="subtitle1" onClick={handleRouteTo} color="secondary">
              Forgot Password?
            </Styled.Typography>
          </Stack>

          {errors.submit && (
            <Box sx={{ mt: 3 }}>
              <FormHelperText error>{errors.submit}</FormHelperText>
            </Box>
          )}

          <Box sx={{ mt: 2 }}>
            <AnimateButton>
              <FormSubmitButton disabled={formSubmitting}>Sign In</FormSubmitButton>
            </AnimateButton>
          </Box>
        </Form>
      )}
    </Formik>
  );
};

export default RestLoginFrom;
