import api from "../../axios/api.js";

export class UsersService {
  async getUsers() {
    const data = await api.get("Users");
    return data;
  }

  async postUsers(body) {
    const data = await api.post("Users", body);
    return data;
  }

  async getUsersCheck(email) {
    const data = await api.get(`Users/check/${email}/`);
    return data;
  }
}
