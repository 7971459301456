import React from "react";
import { RestLoginForm } from "./components/RestLoginForm/RestLoginForm.jsx";
import FormTopTitle from "../../components/FormTopTitle/FormTopTitle";
import FormWrapper from "../../components/FormWrapper";

const EmailModule = ({ setEmailField, setVerificationField }) => {
  return (
    <FormWrapper topTileComponent={<FormTopTitle>Enter your email</FormTopTitle>}>
      <RestLoginForm setEmailField={setEmailField} setVerificationField={setVerificationField} />
    </FormWrapper>
  );
};

export default EmailModule;
