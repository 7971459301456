import React, { useState } from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router";
import TextField from "@mui/material/TextField";
import IconButton from "@mui/material/IconButton";
import SearchIcon from "@mui/icons-material/Search";
import * as Styled from "./SearchSection.styles";

export const SearchSection = () => {
  const history = useHistory();
  const autoCompleteData = useSelector((state) => state.autoComplete);
  const [autoCompleteShow, setAutoCompleteShow] = useState(false);
  const [inputValue, setInputValue] = useState("");

  const handleInputChange = (_, value) => {
    setInputValue(value);
    if (value.length) {
      setAutoCompleteShow(true);
    }
  };

  const handleCloseAutocomplete = () => {
    setAutoCompleteShow(false);
  };

  const handleChangeValue = (_, value) => {
    const idPath = value?.Serial_Number;
    if (idPath) {
      history.push("/details/" + idPath);
    }
  };

  const handleIsEqual = (option, value) => option.label === value.label;

  const handGetOptionalLabel = (option) => option.label;

  return (
    <>
      <Styled.Autocomplete
        open={autoCompleteShow}
        inputValue={inputValue}
        onInputChange={handleInputChange}
        onClose={handleCloseAutocomplete}
        id="country-select-demo"
        sx={{ width: 300 }}
        options={autoCompleteData}
        isOptionEqualToValue={handleIsEqual}
        renderOption={(props, option) => (
          <li {...props} key={option.id}>
            {option.label}
          </li>
        )}
        autoHighlight
        onChange={handleChangeValue}
        getOptionLabel={handGetOptionalLabel}
        renderInput={(params) => (
          <TextField
            {...params}
            label="Search"
            inputProps={{
              ...params.inputProps,
              autoComplete: "new-password"
            }}
          />
        )}
      />

      <IconButton edge="start" size="large" color="primary" aria-label="search serial number" component="span">
        <SearchIcon fontSize="large" />
      </IconButton>
    </>
  );
};
