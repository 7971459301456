import PropTypes from "prop-types";
import React from "react";
import { useTheme } from "@material-ui/styles";
import { Box, Drawer, useMediaQuery } from "@material-ui/core";
import PerfectScrollbar from "react-perfect-scrollbar";
import { BrowserView, MobileView } from "react-device-detect";
import LogoSection from "../../components/LogoSection/LogoSection";
import { MenuList } from "./components/MenuList/MenuList";
import * as Styled from "./SideBarModule.styles";

const SideBarModule = ({ drawerOpen, drawerToggle }) => {
  const classes = Styled.useStyles();
  const theme = useTheme();
  const matchUpMd = useMediaQuery(theme.breakpoints.up("md"));

  return (
    <Styled.Nav aria-label="mailbox folders">
      <Drawer
        container={undefined}
        variant={matchUpMd ? "persistent" : "temporary"}
        anchor="left"
        open={drawerOpen}
        onClose={drawerToggle}
        classes={{
          paper: classes.drawerPaper
        }}
        ModalProps={{ keepMounted: true }}
        color="inherit"
      >
        <Styled.Box>
          <Styled.BoxContainer>
            <LogoSection />
          </Styled.BoxContainer>
        </Styled.Box>
        <BrowserView>
          <PerfectScrollbar component="div" className={classes.ScrollHeight}>
            <MenuList />
          </PerfectScrollbar>
        </BrowserView>
        <MobileView>
          <Box sx={{ px: 2 }}>
            <MenuList />
          </Box>
        </MobileView>
      </Drawer>
    </Styled.Nav>
  );
};

SideBarModule.propTypes = {
  drawerOpen: PropTypes.bool,
  drawerToggle: PropTypes.func,
  window: PropTypes.object
};

export default SideBarModule;
