import React from "react";
import { useTheme } from "@material-ui/core";
import { Typography, useMediaQuery } from "@material-ui/core";

export const TopTitle = () => {
  const theme = useTheme();
  const matchDownSM = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <>
      <Typography data-testid="login-first-title" color={theme.palette.secondary.main} gutterBottom variant={matchDownSM ? "h3" : "h2"}>
        Hi, Welcome Back
      </Typography>

      <Typography data-testid="login-second-title" variant="caption" fontSize="16px" textAlign={matchDownSM ? "center" : ""}>
        Enter your email and password to continue
      </Typography>
    </>
  );
};
