import PropTypes from "prop-types";
import React from "react";
import { motion } from "framer-motion";
import { scale } from "./AnimationButton.styles";

const AnimateButton = React.forwardRef(({ children }, ref) => {
  return (
    <motion.div ref={ref} whileHover={{ scale: scale.hover }} whileTap={{ scale: scale.tap }}>
      {children}
    </motion.div>
  );
});

AnimateButton.propTypes = {
  children: PropTypes.node
};

export default AnimateButton;
