import api from "../../axios/api.js";

export class LicencesService {
  async getLicences() {
    const data = await api.get("Licences");
    return data;
  }

  async postLicences(body) {
    const data = await api.post("Licences", body);
    return data;
  }

  async putLicencesId(licenceId, body) {
    const data = await api.put(`Licences/${licenceId}`, body);
    return data;
  }

  async deleteLicencesId(licenceId) {
    const data = await api.delete(`Licences/${licenceId}`);
    return data;
  }

  async getLicencesCheckType(serialNumber, licenseType) {
    const data = await api.get(`Licences/check/type/${serialNumber}&&${licenseType}`);
    return data;
  }

  async getLicencesCheck(serialNumber) {
    const data = await api.get(`Licences/check/${serialNumber}`);
    return data;
  }
}
