import { useEffect, useState } from "react";
import { adminDashboard, simpleDashboard } from "../helpers/menuItems";
import { useSelector } from "react-redux";

export const useMenuItems = () => {
  const user = useSelector((state) => state.accountDefault);
  const [menuItems, setMenuItems] = useState({
    items: [simpleDashboard]
  });

  useEffect(() => {
    if (user.is_superuser && user.is_staff) {
      setMenuItems({ items: [adminDashboard] });
    } else {
      setMenuItems({ items: [simpleDashboard] });
    }
  }, [user]);

  return menuItems;
};
