import { Typography, Divider as MuiDriver } from "@material-ui/core";
import { styled } from "@material-ui/styles";

export const Title = styled(Typography)(({ theme }) => ({
  ...theme.typography.menuCaption,
  display: "block"
}));

export const Caption = styled(Typography)(({ theme }) => ({
  ...theme.typography.subMenuCaption,
  display: "block"
}));

export const Driver = styled(MuiDriver)(() => ({
  marginTop: "2px",
  marginBottom: "10px"
}));
