import PropTypes from "prop-types";
import React from "react";
import { CardContent } from "@material-ui/core";
import * as Styled from "./MainCard.styles.js";

const MainCard = React.forwardRef(({ children, contentClass, ...others }, ref) => {
  return (
    <Styled.Card ref={ref} {...others}>
      <CardContent className={contentClass}>{children}</CardContent>
    </Styled.Card>
  );
});

MainCard.propTypes = {
  children: PropTypes.node,
  contentClass: PropTypes.string
};

export default MainCard;
