import React from "react";
import { Button } from "@material-ui/core";
import PropTypes from "prop-types";

const FormSubmitButton = ({ disabled, children, ...other }) => {
  return (
    <Button {...other} disableElevation disabled={disabled} fullWidth size="large" type="submit" variant="contained" color="secondary">
      {children}
    </Button>
  );
};

FormSubmitButton.propTypes = {
  disabled: PropTypes.bool,
  children: PropTypes.node
};

export default FormSubmitButton;
