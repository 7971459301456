import React from "react";
import { Link } from "react-router-dom";
import { ButtonBase } from "@material-ui/core";
import Logo from "../UI/Logo";
import config from "../../config/config";

export const LogoSection = () => {
  return (
    <ButtonBase disableRipple component={Link} to={config.defaultPath}>
      <Logo />
    </ButtonBase>
  );
};

export default LogoSection;
