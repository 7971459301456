import React from "react";
import { Box, TextField, Typography } from "@material-ui/core";
import AnimateButton from "../../../../components/AnimatedButton";
import FormSubmitButton from "../../../../components/UI/FormSubmitButton";

export const VerificationForm = ({ verificationNo, setVerificationNo, validVerification, isSubmitting, checkVerification }) => {
  const handleChange = (e) => {
    setVerificationNo(e.target.value);
  };

  return (
    <form noValidate onSubmit={checkVerification}>
      <Box sx={{ marginBottom: 3 }}>
        <TextField required label="Verification Key" fullWidth value={verificationNo} onChange={handleChange} />
        {!validVerification && <Typography color="error">Invalid verification code</Typography>}
      </Box>

      <AnimateButton>
        <FormSubmitButton disabled={isSubmitting}>Reset Password</FormSubmitButton>
      </AnimateButton>
    </form>
  );
};
